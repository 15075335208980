import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import feather from 'feather-icons';
import '../css/styles.css';


const Header = ({ toggleMobileMenu }) => (
    <header className="py-4 shadow-md bg-white">
        <nav className="container mx-auto px-6 flex justify-between items-center">
            <Link to="/" className="text-2xl font-bold flex items-center">
                <div className="logo-icon bg-dark-teal text-white rounded-full w-12 h-12 flex items-center justify-center mr-2 font-bold text-xl">CC</div>
                <span className="logo-text text-dark-teal">Credit Chakra</span>
            </Link>
            <div className="hidden md:flex space-x-6">
                {['Home', 'About', 'Services', 'Products', 'Contact'].map((text, index) => (
                    <Link
                        key={index}
                        to={text === 'Home' ? '/' : `/${text.toLowerCase()}`}
                        className="text-dark-teal hover:text-teal transition duration-300"
                    >
                        {text}
                    </Link>
                ))}
            </div>
            {/* Apply Now button visible on desktop, hidden on mobile */}
            <Link
                to="/apply"
                className="hidden md:inline-block bg-yellow-500 text-dark-teal font-semibold py-2 px-4 rounded-lg hover:bg-yellow-600 transition duration-300"
            >
                Apply Now
            </Link>
            <button
                aria-label="Open mobile menu"
                className="md:hidden text-dark-teal"
                onClick={toggleMobileMenu}
            >
                <i data-feather="menu"></i>
            </button>
        </nav>
    </header>
);

const MobileMenu = ({ mobileMenuOpen, closeMobileMenu }) => (
    <div className={`md:hidden ${mobileMenuOpen ? 'block' : 'hidden'} bg-white`}>
        {['Home', 'About', 'Services', 'Products', 'Contact'].map((text, index) => (
            <Link
                key={index}
                to={text === 'Home' ? '/' : `/${text.toLowerCase()}`} // Set "Home" link to root "/"
                className="block px-4 py-2 text-dark-teal hover:bg-gray-100 transition duration-300"
                onClick={closeMobileMenu} // Close menu on link click
            >
                {text}
            </Link>
        ))}
        <Link 
            to="/apply" 
            className="block px-4 py-2 bg-yellow-500 text-dark-teal font-semibold hover:bg-yellow-600 transition duration-300"
            onClick={closeMobileMenu} // Close menu on "Apply Now" click
        >
            Apply Now
        </Link>
    </div>
);

const Footer = () => (
    <footer className="bg-dark-teal text-white py-12">
        <div className="container mx-auto px-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                <div>
                    <h3 className="text-2xl font-semibold mb-4">Credit Chakra</h3>
                    <p className="mb-4">Empowering MSMEs in India with innovative fintech solutions</p>
                    <div className="flex space-x-4">
                        {['facebook', 'twitter', 'linkedin', 'instagram'].map((icon, index) => (
                            <a key={index} href="#" className="hover:text-yellow-500 transition duration-300">
                                <i data-feather={icon}></i>
                            </a>
                        ))}
                    </div>
                </div>
                <div>
                    <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
                    <ul className="space-y-2">
                        {['About', 'Products', 'Services', 'Blog', 'Contact'].map((text, index) => (
                            <li key={index}>
                                <Link to={`/${text.toLowerCase().replace(/\s+/g, '')}`} className="hover:text-yellow-500 transition duration-300">
                                    {text}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <h3 className="text-xl font-semibold mb-4">Resources</h3>
                    <ul className="space-y-2">
                        {[
                            { name: 'FAQ', path: '/faq' },
                            { name: 'Privacy Policy', path: '/privacy-policy' },
                            { name: 'Terms of Service', path: '/terms-of-service' },
                            { name: 'Careers', path: '/careers' }
                        ].map((resource, index) => (
                            <li key={index}>
                                <Link to={resource.path} className="hover:text-yellow-500 transition duration-300">
                                    {resource.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
                    <p className="mb-2"><i data-feather="map-pin" className="inline-block mr-2"></i> 403, 13th Cross, 8th Main Rd, Sadashivanagar, Armane Nagar, Bengaluru, Karnataka, India, 560080.</p>
                    <p className="mb-2"><i data-feather="phone" className="inline-block mr-2"></i> +91 9900165088</p>
                    <p className="mb-4"><i data-feather="mail" className="inline-block mr-2"></i> contact@creditchakra.com</p>
                    <Link to="/apply" className="bg-yellow-500 text-dark-teal font-semibold py-2 px-4 rounded-lg hover:bg-yellow-600 transition duration-300">
                        Apply Now
                    </Link>
                </div>
            </div>
            <div className="border-t border-teal pt-8 mt-8 text-center">
                <p>&copy; 2024 Credit Chakra. All rights reserved.</p>
            </div>
        </div>
    </footer>
);

const Layout = ({ children }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        feather.replace();
    }, []);

    useEffect(() => {
        // Scroll to top on route change
        window.scrollTo(0, 0);
    }, [location]);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    return (
        <div className="flex flex-col min-h-screen">
            <Header toggleMobileMenu={toggleMobileMenu} />
            <MobileMenu mobileMenuOpen={mobileMenuOpen} closeMobileMenu={closeMobileMenu} />
            <main className="flex-grow">{children}</main>
            <Footer />
        </div>
    );
};

export default Layout;