import React from 'react';

const LoanApplications = () => {
    // Placeholder for loan application data. Replace with actual data fetching logic.
    const applications = [
        {
            contact_name: 'John Doe',
            email: 'john.doe@example.com',
            phone: '1234567890',
            business_name: 'Doe Enterprises',
            business_type: 'Small Enterprise',
            loan_amount: '500000',
            loan_purpose: 'Business Expansion',
            date_created: new Date().toLocaleString(),
        },
        // Add more dummy data or fetch data from backend
    ];

    return (
        <section className="py-16 bg-light-blue">
            <div className="container mx-auto px-6">
                <h1 className="text-3xl font-bold mb-6 text-center text-dark-teal">Submitted Loan Applications</h1>
                {applications.length > 0 ? (
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white rounded-lg shadow-md">
                            <thead>
                                <tr className="bg-yellow-500 text-dark-teal">
                                    <th className="py-2 px-4 text-left">Contact Name</th>
                                    <th className="py-2 px-4 text-left">Email</th>
                                    <th className="py-2 px-4 text-left">Phone</th>
                                    <th className="py-2 px-4 text-left">Business Name</th>
                                    <th className="py-2 px-4 text-left">Business Type</th>
                                    <th className="py-2 px-4 text-left">Loan Amount</th>
                                    <th className="py-2 px-4 text-left">Loan Purpose</th>
                                    <th className="py-2 px-4 text-left">Date Submitted</th>
                                </tr>
                            </thead>
                            <tbody>
                                {applications.map((application, index) => (
                                    <tr key={index} className="border-b">
                                        <td className="py-2 px-4">{application.contact_name}</td>
                                        <td className="py-2 px-4">{application.email}</td>
                                        <td className="py-2 px-4">{application.phone}</td>
                                        <td className="py-2 px-4">{application.business_name}</td>
                                        <td className="py-2 px-4">{application.business_type}</td>
                                        <td className="py-2 px-4">{application.loan_amount}</td>
                                        <td className="py-2 px-4">{application.loan_purpose}</td>
                                        <td className="py-2 px-4">{application.date_created}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p className="text-center text-gray-700">No loan applications have been submitted yet.</p>
                )}
            </div>
        </section>
    );
};

export default LoanApplications;
