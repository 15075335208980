import React, { useState } from 'react';
import { db } from '../firebase'; // Import Firestore setup
import { collection, addDoc, setDoc, doc} from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Validation function
    const validateForm = () => {
        let tempErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{10}$/;

        if (!formData.name.trim()) tempErrors.name = 'Name is required';
        if (!formData.email.trim() || !emailRegex.test(formData.email)) tempErrors.email = 'Valid email is required';
        if (!formData.phone.trim() || !phoneRegex.test(formData.phone)) tempErrors.phone = 'Valid 10-digit phone number is required';
        if (!formData.subject.trim()) tempErrors.subject = 'Subject is required';
        if (!formData.message.trim()) tempErrors.message = 'Message is required';

        setErrors(tempErrors);

        return Object.keys(tempErrors).length === 0;
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            toast.error('Please correct the errors before submitting.');
            return;
        }

        setIsSubmitting(true); // Set loading state

        try {
            // // Add a new document with the form data
            // await addDoc(collection(db, 'contacts'), {
            //     ...formData,
            //     timestamp: new Date()
            // });

            await setDoc(doc(db, "contacts", formData.email), {
                ...formData,
                timestamp: new Date()
            });

            toast.success('Message sent successfully!');
            // Reset the form after submission
            setFormData({
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: ''
            });
            setErrors({});
        } catch (error) {
            console.error('Error adding document: ', error);
            toast.error('Error sending message. Please try again.');
        } finally {
            setIsSubmitting(false); // Remove loading state
        }
    };

    return (
        <div>
            <section className="py-16 bg-light-blue">
                <div className="container mx-auto px-6">
                    <h1 className="text-4xl font-bold mb-8 text-center text-dark-teal">Contact Us</h1>
                    <p className="text-xl mb-12 text-center max-w-3xl mx-auto">
                        Have questions about our products or services? Need financial advice for your MSME? We're here to help. Reach out to us using any of the methods below.
                    </p>

                    <div className="grid md:grid-cols-2 gap-12">
                        <div className="bg-white p-8 rounded-lg shadow-md">
                            <h2 className="text-2xl font-semibold mb-6 text-dark-teal">Send Us a Message</h2>
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div>
                                    <label htmlFor="name" className="block mb-1 font-semibold text-gray-700">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
                                    />
                                    {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                                </div>
                                <div>
                                    <label htmlFor="email" className="block mb-1 font-semibold text-gray-700">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                                    />
                                    {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                                </div>
                                <div>
                                    <label htmlFor="phone" className="block mb-1 font-semibold text-gray-700">Phone Number</label>
                                    <input
                                        type="tel"
                                        id="phone"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 ${errors.phone ? 'border-red-500' : 'border-gray-300'}`}
                                    />
                                    {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
                                </div>
                                <div>
                                    <label htmlFor="subject" className="block mb-1 font-semibold text-gray-700">Subject</label>
                                    <input
                                        type="text"
                                        id="subject"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                        className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 ${errors.subject ? 'border-red-500' : 'border-gray-300'}`}
                                    />
                                    {errors.subject && <p className="text-red-500 text-sm">{errors.subject}</p>}
                                </div>
                                <div>
                                    <label htmlFor="message" className="block mb-1 font-semibold text-gray-700">Message</label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows="4"
                                        value={formData.message}
                                        onChange={handleChange}
                                        className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 ${errors.message ? 'border-red-500' : 'border-gray-300'}`}
                                    ></textarea>
                                    {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="btn btn-primary py-2 px-4 rounded bg-yellow-500 text-dark-teal font-semibold hover:bg-yellow-600 transition duration-300"
                                    >
                                        {isSubmitting ? 'Sending...' : 'Send Message'}
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div>
                            <div className="bg-white p-8 rounded-lg shadow-md mb-8">
                                <h2 className="text-2xl font-semibold mb-6 text-dark-teal">Contact Information</h2>
                                <ul className="space-y-4">
                                    <li className="flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                        <span>403, 13th Cross, 8th Main Rd, Sadashivanagar, Armane Nagar, Bengaluru, Karnataka, India, 560080.</span>
                                    </li>
                                    <li className="flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                        </svg>
                                        <span>+91 9900165088</span>
                                    </li>
                                    <li className="flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                        </svg>
                                        <span>contact@creditchakra.com</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="bg-white p-8 rounded-lg shadow-md">
                                <h2 className="text-2xl font-semibold mb-6 text-dark-teal">Business Hours</h2>
                                <ul className="space-y-2">
                                    <li><span className="font-semibold">Monday - Friday:</span> 9:00 AM - 6:00 PM</li>
                                    <li><span className="font-semibold">Saturday:</span> 10:00 AM - 2:00 PM</li>
                                    <li><span className="font-semibold">Sunday:</span> Closed</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Map Section */}
            <section className="py-16 bg-white">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold mb-8 text-center text-dark-teal">Visit Our Office</h2>
                    <div className="aspect-w-16 aspect-h-9">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3896.1663636746723!2d77.57745391482267!3d13.002591190830055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1680c0506c81%3A0x2c7a4c71ac91c1d7!2s8th%20Main%20Rd%2C%20Sadashiva%20Nagar%2C%20Armane%20Nagar%2C%20Bengaluru%2C%20Karnataka%20560080!5e0!3m2!1sen!2sin!4v1633432248403!5m2!1sen!2sin"
                            width="100%"
                            height="450"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                    </div>
                </div>
            </section>

            {/* Toast Container for Notifications */}
            <ToastContainer />
        </div>
    );
};

export default ContactUs;