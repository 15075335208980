import React from 'react';
import { Link } from 'react-router-dom';
// Import bank logos as React components
import { ReactComponent as HDFCLogo } from '../images/Hdfc.svg';
import { ReactComponent as ICICILogo } from '../images/icici.svg';
import { ReactComponent as IDBIBankLogo } from '../images/IDBIBank.svg';
import { ReactComponent as StateBankofIndiaLogo } from '../images/StateBankofIndia.svg';
import { ReactComponent as BankOfBaroda } from '../images/BankofBaroda.svg';
import { ReactComponent as YesBankLogo } from '../images/YesBank.svg';
import { ReactComponent as PaytmLogo } from '../images/Paytm.svg';
import Marquee from 'react-fast-marquee';
import heroImage1 from '../images/hero-image1.png';

// Sample data for bank logos (replace with your actual data source or API calls)
const bankLogos = [
    { Component: HDFCLogo, alt: 'HDFC Bank' },
    { Component: ICICILogo, alt: 'ICICI Bank' },
    { Component: IDBIBankLogo, alt: 'IDBI Bank' },
    { Component: StateBankofIndiaLogo, alt: 'State Bank of India' },
    { Component: BankOfBaroda, alt: 'Bank of Baroda' },
    { Component: YesBankLogo, alt: 'Yes Bank' },
    { Component: PaytmLogo, alt: 'Paytm Payments Bank' }
];
const HomePage = () => {
    return (
        <div>
            {/* Hero Section */}
            <section className="hero-section py-20 bg-dark-teal text-white">
                <div className="container mx-auto px-6">
                    <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
                        <div className="w-full lg:w-1/2">
                            <h1 className="text-4xl md:text-5xl font-bold mb-6">
                                Empowering MSMEs with Easy Access to Credit and Financial Support
                            </h1>
                            <p className="text-xl mb-8">
                                Get matched with the best loan offers from top lenders and manage your finances seamlessly with Credit Chakra.
                            </p>
                            <Link to="/apply" className="btn btn-primary py-3 px-6 rounded-lg bg-yellow-500 text-dark-teal font-semibold hover:bg-yellow-600 transition duration-300">
                                Get Started Today
                            </Link>
                        </div>
                        <div className="w-full lg:w-1/2 flex justify-center">
                            <img
                                src={heroImage1}
                                alt="MSME Growth"
                                className="rounded-lg shadow-xl max-w-full h-auto"
                                width="500"
                                height="500"
                            />
                        </div>
                    </div>
                </div>
            </section>

            {/* Why Choose Credit Chakra Section */}
            <section className="py-16 bg-light-blue">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold mb-12 text-center text-dark-teal">Why Choose Credit Chakra?</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <ServiceCard
                            iconPath="M13 10V3L4 14h7v7l9-11h-7z"
                            title="Fast, Tailored Loan Matching"
                            description="We connect you with the best loan offers, saving you time and effort with customized solutions."
                        />
                        <ServiceCard
                            iconPath="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                            title="Proactive Repayment Support"
                            description="Our tools monitor your repayments and cash flow, helping you avoid defaults and stay on track."
                        />
                        <ServiceCard
                            iconPath="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                            title="Secure & Private"
                            description="Your data is secure with us. We prioritize your privacy and only ask for information when you're ready."
                        />
                    </div>
                    <div className="mt-12 text-center">
                    <Link to="/apply" className="btn btn-primary py-3 px-6 rounded-lg bg-yellow-500 text-dark-teal font-semibold hover:bg-yellow-600 transition duration-300">
                        GET STARTED TODAY
                    </Link>
                    </div>
                </div>
            </section>

          
            {/* Bank Logos Section */}
            <section className="py-16 bg-air-force-blue">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold mb-12 text-center text-white">Partnered with Leading Banks and Financial Institutions</h2>
                    <Marquee speed={50} gradient={false}>
                        {bankLogos.map((logo, index) => (
                            <div key={index} className="bank-logo mx-4 flex-shrink-0">
                                <logo.Component className="w-32 h-32 object-contain" aria-label={logo.alt} />
                            </div>
                        ))}
                    </Marquee>
                </div>
            </section>

            {/* Financial Challenges Section */}
            <section className="py-16 bg-dark-teal text-white">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold mb-12 text-center">We Understand Your Financial Challenges</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <ServiceCard
                            iconPath="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                            title="Struggling to Get Approved for a Loan?"
                            description="We match you with the right lenders, simplifying the approval process and increasing your chances of success."
                        />
                        <ServiceCard
                            iconPath="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            title="Cash Flow Management Issues?"
                            description="Monitor your cash flow in real-time and get tailored advice to ensure your business stays on track."
                        />
                        <ServiceCard
                            iconPath="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                            title="Need Financial Guidance?"
                            description="We offer ongoing advisory and repayment support, ensuring your financial health is always optimized."
                        />
                    </div>
                    <div className="mt-12 text-center">
                        <Link to="/apply" className="btn btn-primary py-3 px-6 rounded-lg bg-yellow-500 text-dark-teal font-semibold hover:bg-yellow-600 transition duration-300 mobile-cta">
                            Apply Now!
                        </Link>
                    </div>
                </div>
            </section>

            {/* Path to Financial Support Section */}
            <section className="py-16 bg-light-blue text-dark-teal">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold mb-12 text-center">Your Path to Seamless Financial Support Starts Here</h2>
                    <div className="grid md:grid-cols-2 gap-8 items-center">
                        <PathStep
                            stepNumber="1"
                            title="Tell Us About Your Business"
                            description="We make it quick and easy. Just share a few details about your business, and we'll take care of the rest. No complicated forms, no endless paperwork."
                        />
                        <PathStep
                            stepNumber="2"
                            title="Get Personalized Loan Options"
                            description="Our platform analyzes your unique business needs and financial data to match you with the best loan offers from top lenders."
                        />
                        <PathStep
                            stepNumber="3"
                            title="Secure Funds, Simplified"
                            description="We partner with leading banks and NBFCs to ensure fast approvals, and our team handles all the paperwork."
                        />
                        <PathStep
                            stepNumber="4"
                            title="Ongoing Support for Success"
                            description="Our relationship doesn't end with loan disbursement. We provide cash flow monitoring, repayment tracking, and real-time alerts to help you avoid any financial hiccups."
                        />
                    </div>
                    <div className="mt-12 text-center">
                    <h4 className="text-3xl font-bold mb-12 text-center">Ready to grow your business?</h4>
                        <Link to="/apply" className="btn btn-primary py-3 px-6 rounded-lg bg-yellow-500 text-dark-teal font-semibold hover:bg-yellow-600 transition duration-300 mobile-cta">
                            Apply Now!
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    );
};

// ServiceCard Component
const ServiceCard = ({ iconPath, title, description }) => (
    <div className="bg-white p-6 rounded-lg shadow-md text-center hover-card">
        <div className="flex justify-center mb-4">
            <div className="bg-yellow-500 rounded-full p-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={iconPath} />
                </svg>
            </div>
        </div>
        <h3 className="text-xl font-semibold mb-4 text-dark-teal">{title}</h3>
        <p className="text-gray-700">{description}</p>
    </div>
);

// BankLogoScroll Component
const BankLogoScroll = ({ logos }) => (
    <div className="bank-logo-container overflow-hidden">
        <div className="bank-logo-scroll flex">
            {[...Array(2)].map((_, i) =>
                logos.map((logo, index) => (
                    <div key={`${i}-${index}`} className="bank-logo mx-4">
                        <img src={`/static/${logo.src}`} alt={logo.alt} className="w-32 h-32 object-contain" />
                    </div>
                ))
            )}
        </div>
    </div>
);

// PathStep Component
const PathStep = ({ stepNumber, title, description }) => (
    <div className="bg-white p-6 rounded-lg shadow-md text-dark-teal path-step">
        <div className="flex items-center mb-4">
            <div className="bg-yellow-500 rounded-full w-12 h-12 flex items-center justify-center mr-4">
                <span className="text-2xl font-bold text-white">{stepNumber}</span>
            </div>
            <h3 className="text-xl font-semibold">{title}</h3>
        </div>
        <p className="text-gray-700">{description}</p>
    </div>
);

export default HomePage;