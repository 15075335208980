import React from 'react';

const TermsOfService = () => {
    return (
        <section className="terms-of-service py-16 bg-light-blue">
            <div className="container mx-auto px-6">
                <h1 className="text-4xl font-bold mb-8 text-center text-dark-teal">Terms of Service</h1>
                <p className="text-center text-gray-600 mb-12">Last Updated: 28th October, 2024</p>
                
                <div className="text-gray-700 leading-8">
                    <h2 className="text-2xl font-semibold text-dark-teal mb-4">Introduction</h2>
                    <p>
                        Welcome to Credit Chakra (“we,” “us,” or “our”). These Terms of Service (“Terms”) govern your access to and use of our website, mobile application, and services (collectively, the “Services”). By accessing or using our Services, you (“user,” “you,” or “your”) agree to these Terms.
                    </p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">1. Acceptance of Terms</h2>
                    <p>
                        By registering, accessing, or using our Services, you agree to be bound by these Terms, our Privacy Policy, and any additional policies or guidelines provided in connection with the Services.
                    </p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">2. Eligibility</h2>
                    <h3 className="text-xl font-semibold mb-2">2.1 General Eligibility</h3>
                    <p>To use our Services, you must be at least 18 years old, capable of entering into a legally binding agreement, and comply with all applicable laws and regulations.</p>

                    <h3 className="text-xl font-semibold mt-4 mb-2">2.2 MSME Eligibility</h3>
                    <p>MSMEs utilizing our Services must be registered in India and provide necessary documentation to verify business status.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">3. Account Registration and Security</h2>
                    <h3 className="text-xl font-semibold mb-2">3.1 Account Creation</h3>
                    <p>Account creation requires truthful and accurate information to access certain features of our Services.</p>

                    <h3 className="text-xl font-semibold mt-4 mb-2">3.2 Account Security</h3>
                    <p>Maintain the confidentiality of your account credentials and notify us immediately of any unauthorized access.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">4. Services Overview</h2>
                    <ul className="list-disc ml-6">
                        <li>AI-Driven Credit Scoring Models</li>
                        <li>Holistic Loan Lifecycle Support</li>
                        <li>Personalized Financial Advisory Services</li>
                        <li>Integration with Key Financial Data Sources</li>
                        <li>Repayment Support Services</li>
                        <li>MSME Financial Education Programs</li>
                        <li>Data Analytics Services for Financial Institutions</li>
                    </ul>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">5. User Obligations</h2>
                    <h3 className="text-xl font-semibold mb-2">5.1 Compliance</h3>
                    <p>Comply with all applicable laws, regulations, and these Terms when using our Services.</p>

                    <h3 className="text-xl font-semibold mt-4 mb-2">5.2 Prohibited Activities</h3>
                    <ul className="list-disc ml-6">
                        <li>Using the Services for unlawful purposes</li>
                        <li>Submitting false or misleading information</li>
                        <li>Infringing upon others' rights</li>
                        <li>Introducing harmful code</li>
                        <li>Attempting unauthorized access to systems</li>
                    </ul>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">6. Fees and Payments</h2>
                    <p>Certain Services may require fees, which must be paid using specified methods. Failure to pay may result in suspension.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">7. Intellectual Property Rights</h2>
                    <h3 className="text-xl font-semibold mb-2">7.1 Ownership</h3>
                    <p>All content, features, and functionality are owned by Credit Chakra or its licensors.</p>

                    <h3 className="text-xl font-semibold mt-4 mb-2">7.2 Limited License</h3>
                    <p>You are granted a non-exclusive, revocable license to access and use the Services for lawful purposes.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">8. User Content</h2>
                    <h3 className="text-xl font-semibold mb-2">8.1 Responsibility</h3>
                    <p>You are responsible for any content you submit through the Services.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">9. Privacy</h2>
                    <p>Use of our Services is subject to our Privacy Policy, which describes our data collection and protection practices.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">10. Third-Party Services and Links</h2>
                    <p>Credit Chakra is not liable for the content or practices of third-party services linked through our platform.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">11. Disclaimers</h2>
                    <p>The Services are provided “as-is” without warranties. Consult a qualified professional for financial advice.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">12. Limitation of Liability</h2>
                    <p>Our liability is limited to the amount you have paid in the past six months, and we are not liable for indirect damages.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">13. Indemnification</h2>
                    <p>You agree to indemnify Credit Chakra from any claims arising from your misuse of the Services.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">14. Termination</h2>
                    <p>Credit Chakra may suspend or terminate your account for violating these Terms or applicable laws.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">15. Modifications to Services and Terms</h2>
                    <p>We reserve the right to modify the Services or these Terms at any time.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">16. Governing Law and Dispute Resolution</h2>
                    <p>These Terms are governed by the laws of India, and disputes are subject to [Insert City] jurisdiction.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">17. Miscellaneous</h2>
                    <p>These Terms, along with the Privacy Policy, constitute the entire agreement between you and Credit Chakra.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">18. Contact Information</h2>
                    <p>If you have questions, contact us at <a href="mailto:support@creditchakra.com" className="text-dark-teal font-semibold underline">support@creditchakra.com</a>.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">19. Grievance Redressal</h2>
                    <p>Contact our Grievance Officer as per the Information Technology Act for any concerns.</p>
                </div>
            </div>
        </section>
    );
};

export default TermsOfService;