import React from 'react';
import { Link } from 'react-router-dom';

const Services = () => {
    // Array of service data
    const services = [
        {
            title: 'Financial Advisory',
            description: 'Our team of experienced financial advisors provides personalized guidance to help your MSME make informed financial decisions. We analyze your business needs and recommend the best financial products and strategies to support your growth.',
            details: [
                'Business financial health assessment',
                'Cash flow management strategies',
                'Investment planning for business growth',
                'Risk management advice'
            ],
            buttonText: 'Schedule a Consultation',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                </svg>
            )
        },
        {
            title: 'Credit Score Improvement',
            description: 'We help MSMEs understand and improve their credit scores, increasing their chances of securing better financing options. Our experts provide actionable steps to enhance your creditworthiness.',
            details: [
                'Credit score analysis',
                'Personalized improvement plan',
                'Regular progress tracking',
                'Education on credit-building practices'
            ],
            buttonText: 'Boost Your Credit Score',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
            )
        },
        {
            title: 'Digital Financial Management',
            description: 'Embrace the power of digital finance with our cutting-edge tools and platforms. We provide MSMEs with user-friendly digital solutions to streamline financial operations and gain real-time insights into their business performance.',
            details: [
                'Cloud-based accounting software',
                'Digital invoicing and payment solutions',
                'Real-time financial reporting',
                'Integration with popular business tools'
            ],
            buttonText: 'Go Digital',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                </svg>
            )
        },
        {
            title: 'Loan Origination Support',
            description: 'Our Loan Origination Support service assists MSMEs in securing the right loans with minimal hassle. From documentation to approval, we streamline the process for better financing outcomes.',
            details: [
                'Documentation assistance',
                'Loan matching and application support',
                'Pre-approval guidance',
                'Application tracking'
            ],
            buttonText: 'Get Loan Support',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                </svg>
            )
        },
        {
            title: 'Government Schemes and Financial Assistance for MSMEs',
            description: 'We assist MSMEs in navigating various government schemes that offer financial aid, subsidies, and growth opportunities. Our experts help you access the right schemes tailored to your business needs.',
            details: [
                'Pradhan Mantri MUDRA Yojana (PMMY)',
                'CGTMSE-backed collateral-free credit',
                'Stand-Up India Scheme for SC/ST and women entrepreneurs',
                'Emergency Credit Line Guarantee Scheme (ECLGS)'
            ],
            buttonText: 'Access Government Schemes',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                </svg>
            )
        },
        {
            title: 'Value-Added Services for MSMEs',
            description: 'We offer essential value-added services to help MSMEs grow and stay compliant with industry standards. Our team supports you in obtaining crucial certifications and registrations for business success.',
            details: [
                'Udyam Registration for MSME certification',
                'ONDC Merchant Store Setup',
                'ZED Certification for zero-defect manufacturing',
                'Support in acquiring ISO and other certifications'
            ],
            buttonText: 'Leverage Value-Added Services',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            )
        },
        {
            title: 'Business Growth Workshops',
            description: 'Participate in our interactive workshops designed for MSME owners. Learn about the latest trends, best practices, and growth strategies from industry experts.',
            details: [
                'Financial management for MSMEs',
                'Digital transformation strategies',
                'Market expansion techniques',
                'Networking opportunities with fellow MSME entrepreneurs'
            ],
            buttonText: 'Register for Workshops',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                </svg>
            )
        },
        {
            title: 'Regulatory and Compliance Advisory',
            description: 'Stay compliant with RBI directives and financial regulations with our expert guidance. We help MSMEs navigate legal and regulatory complexities with ease.',
            details: [
                'RBI compliance support',
                'Guidance on tax filings and statutory obligations',
                'Loan repayment compliance',
                'Data protection and privacy regulations advisory'
            ],
            buttonText: 'Get Regulatory Guidance',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
            )
        }
    ];

    return (
        <>
            <section className="py-16 bg-light-blue">
                <div className="container mx-auto px-6">
                    <h1 className="text-4xl font-bold mb-8 text-center text-dark-teal">Our Services</h1>
                    <p className="text-xl mb-12 text-center max-w-3xl mx-auto">
                        At Credit Chakra, we offer a comprehensive range of financial services tailored to meet the unique needs of Micro, Small, and Medium Enterprises (MSMEs) in India. Our innovative solutions are designed to empower your business and drive growth.
                    </p>
                    
                    <div className="grid md:grid-cols-2 gap-8">
                        {services.map((service, index) => (
                            <ServiceCard key={index} service={service} />
                        ))}
                    </div>
                </div>
            </section>

            <section className="py-16 bg-dark-teal text-white">
                <div className="container mx-auto px-6 text-center">
                    <h2 className="text-3xl font-bold mb-8">Custom Solutions for Your Business</h2>
                    <p className="text-xl mb-8 max-w-3xl mx-auto text-white">
                        We understand that every MSME is unique. That's why we offer customized financial solutions tailored to your specific business needs. Our team works closely with you to develop a comprehensive financial strategy that aligns with your goals and challenges.
                    </p>
                    <Link to="/contact" className="btn btn-primary py-3 px-6 rounded-lg bg-yellow-500 text-dark-teal font-semibold hover:bg-yellow-600 transition duration-300 inline-block">
                        Request a Custom Solution
                    </Link>
                </div>
            </section>

            <section className="py-16 bg-light-blue">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold mb-8 text-center text-dark-teal">Why Choose Credit Chakra Services?</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <FeatureCard title="Expertise" description="Our team of financial experts brings years of experience in MSME finance, ensuring you receive the best advice and solutions." />
                        <FeatureCard title="Tailored Approach" description="We understand that each business is unique, which is why we offer customized solutions to meet your specific needs and goals." />
                        <FeatureCard title="Innovative Technology" description="Our cutting-edge digital tools and platforms help streamline your financial operations and provide real-time insights for better decision-making." />
                    </div>
                </div>
            </section>

            <section className="py-16 bg-white">
                <div className="container mx-auto px-6 text-center">
                    <h2 className="text-3xl font-bold mb-8 text-dark-teal">Ready to Take Your Business to the Next Level?</h2>
                    <p className="text-xl mb-8 max-w-2xl mx-auto">
                        Discover how Credit Chakra's comprehensive services can help your MSME thrive in today's competitive market. Let's work together to unlock your business's full potential.
                    </p>
                    <Link to="/contact" className="btn btn-primary py-3 px-6 rounded-lg bg-yellow-500 text-dark-teal font-semibold hover:bg-yellow-600 transition duration-300 inline-block">
                        Get Started Today
                    </Link>
                </div>
            </section>
        </>
    );
};

// Reusable ServiceCard component
const ServiceCard = ({ service }) => (
    <div className="bg-white p-8 rounded-lg shadow-md hover-card">
        <div className="flex items-center mb-6">
            <div className="bg-yellow-500 rounded-full p-3 mr-4">
                {service.icon}
            </div>
            <h2 className="text-2xl font-semibold text-dark-teal">{service.title}</h2>
        </div>
        <p className="mb-6 text-gray-700">{service.description}</p>
        <ul className="list-disc list-inside mb-6 text-gray-700">
            {service.details.map((detail, i) => (
                <li key={i}>{detail}</li>
            ))}
        </ul>
        <Link to="/contact" className="btn btn-primary py-2 px-4 rounded bg-yellow-500 text-dark-teal font-semibold hover:bg-yellow-600 transition duration-300 inline-block">
            {service.buttonText}
        </Link>
    </div>
);

// Reusable FeatureCard component for "Why Choose Credit Chakra Services?"
const FeatureCard = ({ title, description }) => (
    <div className="bg-white p-6 rounded-lg shadow-md text-center hover-card">
        <div className="flex justify-center mb-4">
            <div className="bg-yellow-500 rounded-full p-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                </svg>
            </div>
        </div>
        <h3 className="text-xl font-semibold mb-4 text-dark-teal">{title}</h3>
        <p className="text-gray-700">{description}</p>
    </div>
);

export default Services;