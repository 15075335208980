import React, { useState } from 'react';

const Blog = () => {
    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleReadMore = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEmail('');
        setError('');
    };

    const handleSubscribe = () => {
        if (!email) {
            setError('Email is required');
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setError('Please enter a valid email');
        } else {
            setError('');
            // Here you can add the logic for handling the subscription (e.g., API call)
            alert('Thank you for subscribing!');
            handleCloseModal();
        }
    };

    const sampleBlogs = [
        {
            title: "The Importance of Financial Literacy for MSMEs",
            excerpt: "Understanding financial principles is crucial for MSMEs to thrive in a competitive market...",
            date: "October 15, 2024"
        },
        {
            title: "Top 5 AI Trends in Fintech for 2024",
            excerpt: "Discover the latest trends in AI that are transforming the fintech industry, from predictive analytics to chatbots...",
            date: "October 10, 2024"
        },
        {
            title: "How Credit Chakra is Empowering Small Businesses",
            excerpt: "With our innovative loan products, we are helping small businesses overcome financial challenges...",
            date: "October 5, 2024"
        },
        {
            title: "Understanding Supply Chain Financing for MSMEs",
            excerpt: "Supply chain financing can unlock working capital for businesses. Learn how it works and its benefits...",
            date: "September 28, 2024"
        },
        {
            title: "Why Data Security Matters in Fintech",
            excerpt: "Data security is a top priority for any fintech company. Here’s how we ensure the safety of your data...",
            date: "September 20, 2024"
        },
        {
            title: "The Future of Digital Lending in India",
            excerpt: "As digital lending continues to grow, we explore what the future holds for this industry in India...",
            date: "September 15, 2024"
        }
    ];

    return (
        <section className="blog-section py-16 bg-light-blue">
            <div className="container mx-auto px-6">
                <h1 className="text-4xl font-bold text-center text-dark-teal mb-8">Latest Blogs</h1>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {sampleBlogs.map((blog, index) => (
                        <div key={index} className="bg-white shadow-lg rounded-lg p-6">
                            <h2 className="text-2xl font-semibold text-dark-teal mb-2">{blog.title}</h2>
                            <p className="text-sm text-gray-500 mb-4">{blog.date}</p>
                            <p className="text-gray-700 mb-4">{blog.excerpt}</p>
                            <button
                                onClick={handleReadMore}
                                className="text-dark-teal font-semibold hover:underline"
                            >
                                Read More
                            </button>
                        </div>
                    ))}
                </div>

                {showModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
                            <h2 className="text-2xl font-bold text-dark-teal mb-4">Subscribe to Our Newsletter</h2>
                            <p className="text-gray-700 mb-4">
                                Sign up for our newsletter to stay updated with the latest insights and updates from Credit Chakra.
                            </p>
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full px-4 py-2 border rounded mb-4 focus:outline-none focus:ring-2 focus:ring-dark-teal"
                            />
                            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
                            <div className="flex justify-end">
                                <button
                                    onClick={handleCloseModal}
                                    className="text-gray-600 hover:text-gray-800 mr-4"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleSubscribe}
                                    className="bg-dark-teal text-white px-4 py-2 rounded hover:bg-teal-600"
                                >
                                    Subscribe
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default Blog;