import React from 'react';
import { Link } from 'react-router-dom';
import '../css/styles.css'; // Ensure your custom styles are included
import aboutImage from '../images/about-image.png';

const About = () => {
    return (
        <>
            {/* Mission and Vision Section */}
            <section className="py-16 bg-light-blue">
                <div className="container mx-auto px-6">
                    <h1 className="text-4xl font-bold mb-8 text-center text-dark-teal">About Credit Chakra</h1>

                    <div className="grid md:grid-cols-2 gap-12 items-center">
                        <div>
                            <h2 className="text-2xl font-semibold mb-4 text-dark-teal">Our Mission</h2>
                            <p className="mb-6">
                                At Credit Chakra, we are dedicated to empowering Micro, Small, and Medium Enterprises (MSMEs) in India by providing innovative fintech solutions that simplify access to credit and financial support. Our mission is to fuel the growth of small businesses and contribute to the economic development of the nation.
                            </p>
                            
                            <h2 className="text-2xl font-semibold mb-4 text-dark-teal">Our Vision</h2>
                            <p className="mb-6">
                                We envision a future where every MSME in India has easy access to tailored financial solutions, enabling them to thrive and contribute to a robust economy. Credit Chakra aims to be the catalyst that transforms the financial landscape for small businesses across the country.
                            </p>
                        </div>
                        <div className="flex justify-center">
                            <img src={aboutImage} alt="Credit Chakra Team" className="rounded-lg shadow-xl max-w-full h-auto" />
                        </div>
                    </div>
                </div>
            </section>

            {/* Core Values Section */}
            <section className="py-16 bg-white">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold mb-12 text-center text-dark-teal">Our Core Values</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <CoreValueCard
                            title="Integrity"
                            description="We uphold the highest standards of honesty and transparency in all our dealings, ensuring trust and reliability."
                            iconPath="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                        />
                        <CoreValueCard
                            title="Innovation"
                            description="We constantly strive to develop cutting-edge solutions that address the unique challenges faced by MSMEs in India."
                            iconPath="M13 10V3L4 14h7v7l9-11h-7z"
                        />
                        <CoreValueCard
                            title="Empowerment"
                            description="We are committed to empowering MSMEs with the financial tools and knowledge they need to succeed and grow."
                            iconPath="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                        />
                    </div>
                </div>
            </section>

            {/* Impact Section */}
            <section className="py-16 bg-dark-teal text-white">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold mb-12 text-center">Our Impact</h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <ImpactStat title="50+" description="MSMEs Supported" />
                        <ImpactStat title="₹10Cr+" description="Loans Facilitated" />
                        <ImpactStat title="12+" description="Banking Partners" />
                    </div>
                </div>
            </section>

            {/* Call-to-Action Section */}
            <section className="py-16 bg-white">
                <div className="container mx-auto px-6 text-center">
                    <h2 className="text-3xl font-bold mb-8 text-dark-teal">Join Us in Empowering MSMEs</h2>
                    <p className="text-xl mb-8 max-w-2xl mx-auto">
                        Together, we can create a brighter future for small businesses across India. Whether you're an MSME looking for financial support or a potential partner, we'd love to hear from you.
                    </p>
                    <Link to="/contact" className="btn btn-primary py-3 px-6 rounded-lg bg-yellow-500 text-dark-teal font-semibold hover:bg-yellow-600 transition duration-300">
                        Get in Touch
                    </Link>
                </div>
            </section>
        </>
    );
};

// CoreValueCard Component for Core Values section
const CoreValueCard = ({ title, description, iconPath }) => (
    <div className="bg-light-blue p-6 rounded-lg shadow-md text-center hover-card">
        <div className="flex justify-center mb-4">
            <div className="bg-yellow-500 rounded-full p-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={iconPath} />
                </svg>
            </div>
        </div>
        <h3 className="text-xl font-semibold mb-4 text-dark-teal">{title}</h3>
        <p className="text-gray-700">{description}</p>
    </div>
);

// ImpactStat Component for Impact section
const ImpactStat = ({ title, description }) => (
    <div className="text-center">
        <div className="text-4xl font-bold mb-2">{title}</div>
        <p className="text-white">{description}</p>
    </div>
);

export default About;