import React from 'react';

const ContactMessages = () => {
    // Placeholder for contact messages. Replace with actual data fetching logic.
    const contacts = [
        {
            name: 'Jane Doe',
            email: 'jane.doe@example.com',
            phone: '0987654321',
            subject: 'Inquiry about loan application',
            message: 'I want to know more about the application process.',
            date_created: new Date().toLocaleString(),
        },
        // Add more dummy data or fetch data from backend
    ];

    return (
        <section className="py-16 bg-light-blue">
            <div className="container mx-auto px-6">
                <h1 className="text-3xl font-bold mb-6 text-center text-dark-teal">Submitted Contact Messages</h1>
                {contacts.length > 0 ? (
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white rounded-lg shadow-md">
                            <thead>
                                <tr className="bg-yellow-500 text-dark-teal">
                                    <th className="py-2 px-4 text-left">Name</th>
                                    <th className="py-2 px-4 text-left">Email</th>
                                    <th className="py-2 px-4 text-left">Phone</th>
                                    <th className="py-2 px-4 text-left">Subject</th>
                                    <th className="py-2 px-4 text-left">Message</th>
                                    <th className="py-2 px-4 text-left">Date Submitted</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contacts.map((contact, index) => (
                                    <tr key={index} className="border-b">
                                        <td className="py-2 px-4">{contact.name}</td>
                                        <td className="py-2 px-4">{contact.email}</td>
                                        <td className="py-2 px-4">{contact.phone}</td>
                                        <td className="py-2 px-4">{contact.subject}</td>
                                        <td className="py-2 px-4">{contact.message}</td>
                                        <td className="py-2 px-4">{contact.date_created}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p className="text-center text-gray-700">No contact messages have been submitted yet.</p>
                )}
            </div>
        </section>
    );
};

export default ContactMessages;
