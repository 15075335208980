import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc, setDoc, doc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Apply = () => {
    const [formData, setFormData] = useState({
        businessName: '',
        contactName: '',
        email: '',
        phone: '',
        businessType: '',
        loanAmount: '',
        purpose: ''
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateForm = () => {
        let tempErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{10}$/;

        if (!formData.businessName.trim()) tempErrors.businessName = 'Business name is required';
        if (!formData.contactName.trim()) tempErrors.contactName = 'Contact name is required';
        if (!formData.email.trim() || !emailRegex.test(formData.email)) tempErrors.email = 'Valid email is required';
        if (!formData.phone.trim() || !phoneRegex.test(formData.phone)) tempErrors.phone = 'Valid 10-digit phone number is required';
        if (!formData.businessType) tempErrors.businessType = 'Business type is required';
        if (!formData.loanAmount || isNaN(formData.loanAmount) || formData.loanAmount <= 0) tempErrors.loanAmount = 'Positive loan amount is required';
        if (!formData.purpose.trim()) tempErrors.purpose = 'Purpose of the loan is required';

        setErrors(tempErrors);

        return Object.keys(tempErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            toast.error('Please correct the errors before submitting.');
            return;
        }

        setIsSubmitting(true);

        try {
            // await addDoc(collection(db, 'applications'), {
            //     ...formData,
            //     timestamp: new Date()
            // });

            await setDoc(doc(db, "applications", formData.email), {
                ...formData,
                timestamp: new Date()
            });

            toast.success('Application submitted successfully!');
            setFormData({
                businessName: '',
                contactName: '',
                email: '',
                phone: '',
                businessType: '',
                loanAmount: '',
                purpose: ''
            });
            setErrors({});
        } catch (error) {
            console.error('Error adding document: ', error);
            toast.error('Error submitting application. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <section className="py-16 bg-light-blue">
            <div className="container mx-auto px-6">
                <h1 className="text-3xl font-bold mb-6 text-center text-dark-teal">Apply for Credit Chakra Loans</h1>
                <p className="text-xl mb-8 text-center">
                    Start your journey towards financial growth and success with Credit Chakra. Fill out the form below to apply for our loans.
                </p>
                
                <div className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-md">
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label htmlFor="businessName" className="block mb-1 font-semibold">Business Name</label>
                            <input
                                type="text"
                                id="businessName"
                                name="businessName"
                                value={formData.businessName}
                                onChange={handleChange}
                                className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 ${errors.businessName ? 'border-red-500' : 'border-gray-300'}`}
                            />
                            {errors.businessName && <p className="text-red-500 text-sm">{errors.businessName}</p>}
                        </div>
                        <div>
                            <label htmlFor="contactName" className="block mb-1 font-semibold">Contact Name</label>
                            <input
                                type="text"
                                id="contactName"
                                name="contactName"
                                value={formData.contactName}
                                onChange={handleChange}
                                className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 ${errors.contactName ? 'border-red-500' : 'border-gray-300'}`}
                            />
                            {errors.contactName && <p className="text-red-500 text-sm">{errors.contactName}</p>}
                        </div>
                        <div>
                            <label htmlFor="email" className="block mb-1 font-semibold">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                            />
                            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                        </div>
                        <div>
                            <label htmlFor="phone" className="block mb-1 font-semibold">Phone Number</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 ${errors.phone ? 'border-red-500' : 'border-gray-300'}`}
                            />
                            {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
                        </div>
                        <div>
                            <label htmlFor="businessType" className="block mb-1 font-semibold">Business Type</label>
                            <select
                                id="businessType"
                                name="businessType"
                                value={formData.businessType}
                                onChange={handleChange}
                                className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 ${errors.businessType ? 'border-red-500' : 'border-gray-300'}`}
                            >
                                <option value="">Select Business Type</option>
                                <option value="micro">Micro Enterprise</option>
                                <option value="small">Small Enterprise</option>
                                <option value="medium">Medium Enterprise</option>
                            </select>
                            {errors.businessType && <p className="text-red-500 text-sm">{errors.businessType}</p>}
                        </div>
                        <div>
                            <label htmlFor="loanProduct" className="block mb-1 font-semibold">Select Loan Product</label>
                            <select
                                id="loanProduct"
                                name="loanProduct"
                                value={formData.loanProduct}
                                onChange={handleChange}
                                className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 ${errors.loanProduct ? 'border-red-500' : 'border-gray-300'}`}
                            >
                                <option value="">Select Loan Product</option>
                                <option value="chakraQuickLoan">Chakra Quick Loan</option>
                                <option value="chakraGrowthFund">Chakra Growth Fund</option>
                                <option value="chakraOverdraftFacility">Chakra Overdraft Facility</option>
                                <option value="chakraInvoiceFinancing">Chakra Invoice Financing</option>
                                <option value="chakraSupplyChainFinancing">Chakra Supply Chain Financing</option>
                                <option value="chakraRevenueBasedFinancing">Chakra Revenue-Based Financing</option>
                            </select>
                            {errors.loanProduct && <p className="text-red-500 text-sm">{errors.loanProduct}</p>}
                        </div>
                        <div>
                            <label htmlFor="loanAmount" className="block mb-1 font-semibold">Desired Loan Amount (in INR)</label>
                            <input
                                type="number"
                                id="loanAmount"
                                name="loanAmount"
                                value={formData.loanAmount}
                                onChange={handleChange}
                                className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 ${errors.loanAmount ? 'border-red-500' : 'border-gray-300'}`}
                            />
                            {errors.loanAmount && <p className="text-red-500 text-sm">{errors.loanAmount}</p>}
                        </div>
                        <div>
                            <label htmlFor="purpose" className="block mb-1 font-semibold">Loan Purpose</label>
                            <textarea
                                id="purpose"
                                name="purpose"
                                rows="3"
                                value={formData.purpose}
                                onChange={handleChange}
                                className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 ${errors.purpose ? 'border-red-500' : 'border-gray-300'}`}
                            ></textarea>
                            {errors.purpose && <p className="text-red-500 text-sm">{errors.purpose}</p>}
                        </div>
                        <div className="text-center">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="bg-yellow-500 text-dark-teal font-semibold py-2 px-6 rounded hover:bg-yellow-600 transition duration-300"
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit Application'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {/* Toast Container for Notifications */}
            <ToastContainer />
        </section>
    );
};

export default Apply;