// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDnYYpCfdokqLy9vP7MCnR-MwVG6d34dMc",
    authDomain: "credit-chakra-in.firebaseapp.com",
    projectId: "credit-chakra-in",
    storageBucket: "credit-chakra-in.appspot.com",
    messagingSenderId: "20491936969",
    appId: "1:20491936969:web:2e555f7891588558105918",
    measurementId: "G-HQQ12R73G6"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export {app, db,analytics };