import React from 'react';

const Careers = () => {
    return (
        <section className="careers-section py-16 bg-light-blue">
            <div className="container mx-auto px-6">
                <h1 className="text-4xl font-bold text-center text-dark-teal mb-4">Careers at Credit Chakra</h1>
                <p className="text-xl text-center text-gray-600 mb-12">
                    Join Us in Empowering India’s MSMEs
                </p>

                <div className="text-gray-700 leading-8">
                    <h2 className="text-2xl font-semibold text-dark-teal mb-4">Why Work With Us?</h2>

                    {[
                        {
                            title: "1. Mission-Driven Impact",
                            description: "Be part of a company that is dedicated to making a real difference in the lives of MSME owners across India.",
                        },
                        {
                            title: "2. Innovative Environment",
                            description: "Join a team that leverages AI, machine learning, and data analytics to revolutionize the fintech landscape.",
                        },
                        {
                            title: "3. Professional Growth",
                            description: "Access continuous learning opportunities, mentorship programs, and challenging projects to enhance your skills.",
                        },
                        {
                            title: "4. Collaborative Culture",
                            description: "Experience a supportive work environment where collaboration and diverse perspectives drive better solutions.",
                        },
                        {
                            title: "5. Competitive Benefits",
                            description: "Enjoy competitive salaries, health insurance, performance bonuses, and more.",
                        },
                    ].map((item, index) => (
                        <div key={index} className="mb-6 p-4 rounded-lg shadow-lg bg-white">
                            <h3 className="font-semibold text-dark-teal mb-2">{item.title}</h3>
                            <p>{item.description}</p>
                        </div>
                    ))}

                    <h2 className="text-2xl font-semibold text-dark-teal mt-10 mb-4">Our Core Values</h2>
                    <ul className="list-disc list-inside space-y-2 mb-8">
                        <li><strong>Innovation:</strong> Embrace new ideas and technologies.</li>
                        <li><strong>Integrity:</strong> Uphold the highest ethical standards.</li>
                        <li><strong>Inclusion:</strong> Foster a diverse and inclusive workplace.</li>
                        <li><strong>Customer-Centricity:</strong> Prioritize customer needs.</li>
                        <li><strong>Excellence:</strong> Strive for excellence in all actions.</li>
                    </ul>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">Current Opportunities</h2>
                    <p className="mb-8">We’re building our foundational team and looking for enthusiastic individuals to join us in our growth journey.</p>

                    {/* Job Positions */}
                    {[
                        {
                            title: "Software Engineer (Full Stack)",
                            location: "Bengaluru / Remote",
                            type: "Intern",
                            responsibilities: [
                                "Develop and maintain web and mobile applications.",
                                "Collaborate with cross-functional teams to define and ship new features.",
                                "Ensure application performance and responsiveness.",
                                "Implement security and data protection measures."
                            ],
                            qualifications: [
                                "Bachelor’s degree in Computer Science or related field.",
                                "2+ years in full-stack development.",
                                "Proficiency in JavaScript, Python, or Java.",
                                "Experience with React, Angular, or Node.js.",
                                "Familiarity with SQL and NoSQL databases.",
                                "Cloud services experience (AWS, Azure) is a plus."
                            ]
                        },
                        {
                            title: "Data Scientist / AI Specialist",
                            location: "Bengaluru / Remote",
                            type: "Intern",
                            responsibilities: [
                                "Develop AI-driven credit scoring models.",
                                "Analyze large datasets to improve decision-making.",
                                "Collaborate with engineering to integrate models.",
                                "Stay updated on AI and fintech trends."
                            ],
                            qualifications: [
                                "Bachelor’s degree in Data Science or related field.",
                                "3+ years in data science and machine learning.",
                                "Proficiency in Python or R.",
                                "Experience with TensorFlow or PyTorch.",
                                "Strong analytical skills."
                            ]
                        },
                        {
                            title: "Financial Analyst",
                            location: "Bengaluru / Remote",
                            type: "Intern",
                            responsibilities: [
                                "Conduct financial analysis for MSME clients.",
                                "Develop financial models for advisory services.",
                                "Create personalized financial plans.",
                                "Collaborate to enhance financial tools."
                            ],
                            qualifications: [
                                "Bachelor’s degree in Finance or related field.",
                                "2+ years in financial analysis or advisory.",
                                "Strong knowledge of financial statements.",
                                "Certification such as CFA or CA is a plus."
                            ]
                        }
                    ].map((job, index) => (
                        <div key={index} className="job-card mb-8 p-6 bg-white rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold text-dark-teal">{job.title}</h3>
                            <p className="text-sm mb-2">{job.location} • {job.type}</p>
                            <h4 className="font-semibold mb-2">Responsibilities:</h4>
                            <ul className="list-disc list-inside mb-4 space-y-1 pl-4">
                                {job.responsibilities.map((resp, i) => (
                                    <li key={i}>{resp}</li>
                                ))}
                            </ul>
                            <h4 className="font-semibold mb-2">Qualifications:</h4>
                            <ul className="list-disc list-inside space-y-1 pl-4">
                                {job.qualifications.map((qual, i) => (
                                    <li key={i}>{qual}</li>
                                ))}
                            </ul>
                        </div>
                    ))}

                    <h2 className="text-2xl font-semibold text-dark-teal mt-10 mb-4">How to Apply</h2>
                    <p className="mb-4">
                        Send your resume and cover letter explaining why you’re a good fit for Credit Chakra to <a href="mailto:careers@creditchakra.com" className="text-dark-teal font-semibold underline">careers@creditchakra.com</a> with the subject line “Application for [Position Title] - [Your Name].”
                    </p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">Life at Credit Chakra</h2>
                    <ul className="list-disc list-inside space-y-2">
                        <li>Startup Culture: Agile and innovative environment where your ideas matter.</li>
                        <li>Flexible Work Arrangements: Options for remote work and flexible hours.</li>
                        <li>Team Events: Regular team-building activities.</li>
                        <li>Learning Opportunities: Access to industry conferences, training, and workshops.</li>
                    </ul>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-10 mb-4">Diversity and Inclusion</h2>
                    <p className="mb-8">
                        We are committed to creating an inclusive workplace. We welcome applicants from all backgrounds, regardless of race, color, religion, gender identity, sexual orientation, national origin, or disability.
                    </p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-10 mb-4">Contact Us</h2>
                    <p>For any queries regarding career opportunities, please reach out to us:</p>
                    <p>Email: <a href="mailto:careers@creditchakra.com" className="text-dark-teal font-semibold underline">careers@creditchakra.com</a></p>
                    <p>Phone: +91-9538731848 (Mon-Fri, 9 AM - 6 PM IST)</p>
                </div>
            </div>
        </section>
    );
};

export default Careers;