import React from 'react';

const PrivacyPolicy = () => {
    return (
        <section className="privacy-policy py-16 bg-light-blue">
            <div className="container mx-auto px-6">
                <h1 className="text-4xl font-bold mb-8 text-center text-dark-teal">Privacy Policy</h1>
                <p className="text-center text-gray-600 mb-12">Last Updated: 28th October, 2024</p>
                
                <div className="text-gray-700 leading-8">
                    <h2 className="text-2xl font-semibold text-dark-teal mb-4">Introduction</h2>
                    <p>
                        At Credit Chakra (“we,” “us,” or “our”), we are committed to protecting the privacy and security of the personal information of our users. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you access or use our website, mobile application, and services. By accessing or using our Services, you agree to the terms of this Privacy Policy.
                    </p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">Scope</h2>
                    <p>
                        This Privacy Policy applies to all users of Credit Chakra’s Services, including MSMEs, financial institutions, partners, and website visitors. It governs the handling of personal information collected in compliance with applicable laws.
                    </p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">Definitions</h2>
                    <ul className="list-disc ml-6 mb-4">
                        <li><strong>Personal Information:</strong> Information relating to an identified or identifiable natural person.</li>
                        <li><strong>Sensitive Personal Data:</strong> Includes passwords, financial information, biometric data, and more.</li>
                        <li><strong>Services:</strong> All products and features offered by Credit Chakra.</li>
                    </ul>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">1. Information We Collect</h2>
                    <h3 className="text-xl font-semibold mb-2">1.1 Information You Provide to Us</h3>
                    <p>Personal information collected includes registration details, application information, and communication details.</p>

                    <h3 className="text-xl font-semibold mt-4 mb-2">1.2 Information Collected Automatically</h3>
                    <p>We may automatically collect device information, usage data, and location data (with consent).</p>

                    <h3 className="text-xl font-semibold mt-4 mb-2">1.3 Information from Third Parties</h3>
                    <p>We may receive information from financial institutions, public databases, and credit bureaus for verification and assessment purposes.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">2. How We Use Your Information</h2>
                    <p>We use your information for credit assessment, service improvement, communication, and legal compliance.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">3. Disclosure of Your Information</h2>
                    <ul className="list-disc ml-6">
                        <li>With consent for third-party services.</li>
                        <li>With service providers who perform services on our behalf.</li>
                        <li>For compliance and legal requirements.</li>
                        <li>In connection with corporate transactions.</li>
                    </ul>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">4. Security Measures</h2>
                    <p>We implement robust security measures, including encryption, access controls, and data minimization.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">5. Cookies and Tracking Technologies</h2>
                    <p>We use cookies to enhance user experience, analyze usage, and support marketing efforts.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">6. Third-Party Services</h2>
                    <p>Our Services may link to third-party sites. We are not responsible for their privacy practices.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">7. Data Retention</h2>
                    <p>We retain your data for as long as necessary for service delivery, legal obligations, or account maintenance.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">8. Your Rights and Choices</h2>
                    <p>You have the right to access, correct, delete, and manage the processing of your information.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">9. Children’s Privacy</h2>
                    <p>Our Services are not intended for individuals under 18, and we do not knowingly collect data from minors.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">10. International Data Transfers</h2>
                    <p>By using our Services from outside India, you consent to data transfer to India.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">11. Changes to This Privacy Policy</h2>
                    <p>We may update this policy. Significant changes will be communicated via our website or email notifications.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">12. Contact Us</h2>
                    <p>If you have questions, please contact us at <a href="mailto:privacy@creditchakra.com" className="text-dark-teal font-semibold underline">privacy@creditchakra.com</a> or +91-9538731848.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">13. Grievance Officer</h2>
                    <p>In compliance with the Information Technology Act, you may contact our Grievance Officer:</p>
                    <ul className="list-disc ml-6">
                        {/* <li><strong>Name:</strong> [Grievance Officer’s Name]</li> */}
                        <li><strong>Email:</strong> grievance@creditchakra.com</li>
                        {/* <li><strong>Phone:</strong> +91-9538731848</li>
                        <li><strong>Address:</strong> [Insert Physical Address]</li> */}
                    </ul>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">14. User Consent</h2>
                    <p>By using our Services, you consent to the terms outlined in this Privacy Policy.</p>

                    <h2 className="text-2xl font-semibold text-dark-teal mt-8 mb-4">15. Governing Law and Jurisdiction</h2>
                    <p>This Privacy Policy is governed by the laws of India. Any disputes will be handled by the courts in Bengaluru, India.</p>
                </div>
            </div>
        </section>
    );
};

export default PrivacyPolicy;