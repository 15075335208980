import React from 'react';
import { Link } from 'react-router-dom';

const MessageSuccess = () => {
    return (
        <section className="py-16 bg-light-blue">
            <div className="container mx-auto px-6 text-center">
                <h1 className="text-4xl font-bold mb-8 text-dark-teal">Message Sent Successfully</h1>
                <p className="text-xl mb-12 max-w-3xl mx-auto">
                    Thank you for contacting Credit Chakra. We have received your message and our team will get back to you within 1-2 business days.
                </p>
                <Link 
                    to="/" 
                    className="btn btn-primary py-3 px-6 rounded-lg bg-yellow-500 text-dark-teal font-semibold hover:bg-yellow-600 transition duration-300 inline-block"
                >
                    Back to Home
                </Link>
            </div>
        </section>
    );
};

export default MessageSuccess;
