import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Products from './components/Products';
import Contact from './components/Contact';
import Apply from './components/Apply';
import Blog from './components/Blog';
import Faq from './components/Faq';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import Careers from './components/Careers';
import MessageSuccess from './components/MessageSuccess'; // Import the MessageSuccess component
import LoanApplications from './components/LoanApplications'; // Import LoanApplications component
import ContactMessages from './components/ContactMessages'; // Import ContactMessages component

function App() {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/apply" element={<Apply />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-service" element={<TermsOfService />} />
                    <Route path="/careers" element={<Careers />} />
                    <Route path="/message-success" element={<MessageSuccess />} /> {/* Add the MessageSuccess route */}
                    <Route path="/loan-applications" element={<LoanApplications />} /> {/* Add the LoanApplications route */}
                    <Route path="/contact-messages" element={<ContactMessages />} /> {/* Add the ContactMessages route */}
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;
