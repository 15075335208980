import React, { useState } from 'react';

const FAQ = () => {
    const faqs = [
        {
            question: 'What is Credit Chakra?',
            answer: 'Credit Chakra is a fintech platform dedicated to empowering Micro, Small, and Medium Enterprises (MSMEs) in India by providing innovative financial solutions throughout the loan lifecycle. We offer services such as AI-driven credit scoring, holistic loan support, personalized financial advisory, and integration with key financial data sources to enhance financial inclusion and support MSME growth.'
        },
        {
            question: 'Who can use Credit Chakra’s services?',
            answer: 'Our services are designed for:\n\n• MSMEs across various sectors in India seeking access to credit, financial management tools, and personalized advisory services.\n• Financial Institutions like banks and Non-Banking Financial Companies (NBFCs) interested in enhancing their MSME lending operations through advanced analytics and broader customer reach.'
        },
        {
            question: 'How does Credit Chakra benefit MSMEs?',
            answer: 'Credit Chakra simplifies access to credit by:\n\n• Streamlining Loan Applications: Our platform offers an easy-to-use interface for applying for loans.\n• AI-Driven Credit Assessments: Utilizing advanced algorithms to improve approval rates and reduce biases.\n• Repayment Support: Providing tools to manage repayments effectively.\n• Financial Advisory: Offering personalized advice to improve financial health.\n• Educational Programs: Enhancing financial literacy through workshops and resources.'
        },
        {
            question: 'What is AI-driven credit scoring, and how does it work?',
            answer: 'Our AI-driven credit scoring models use artificial intelligence and machine learning algorithms to assess the creditworthiness of MSMEs more accurately and efficiently. By analyzing both traditional financial data and alternative data sources, our models provide real-time risk assessments, improving the chances of loan approval for deserving businesses.'
        },
        {
            question: 'How does Credit Chakra collaborate with financial institutions?',
            answer: 'We partner with banks, NBFCs, and other financial institutions to:\n\n• Provide Advanced Credit Assessment Tools: Enhancing their ability to assess MSME creditworthiness.\n• Offer Data Analytics Services: Supplying insights into market trends and borrower behaviors.\n• Expand Customer Base: Connecting them with a wider network of MSME clients.'
        },
        {
            question: 'Is Credit Chakra regulated and compliant with industry standards?',
            answer: 'Yes, Credit Chakra adheres to all applicable regulations set by the Reserve Bank of India (RBI) and other regulatory bodies. We are committed to maintaining the highest standards of compliance, data security, and ethical practices.'
        },
        {
            question: 'What documents are required for the loan application?',
            answer: 'Typically required documents include:\n\n• Business Registration Proof: Udyam Registration, GST Certificate, etc.\n• Financial Statements: Balance sheets, profit & loss statements.\n• Bank Statements: Recent six months’ statements.\n• Tax Returns: Latest Income Tax and GST returns.\n• KYC Documents: Identification and address proofs of business owners.'
        },
        {
            question: 'Are there any fees associated with Credit Chakra’s services?',
            answer: '• Loan Applications: Credit Chakra does not charge any upfront fees for loan applications. However, certain processing fees or charges may apply, depending on the lending institution and loan product. All fees will be transparently communicated during the application process.\n• Financial Advisory Services: Some basic resources are free for registered users. Fees for personalized consultations or premium services will be clearly outlined before booking.'
        },
        {
            question: 'How does Credit Chakra ensure data security and privacy?',
            answer: 'We ensure security through:\n\n• Encryption: All data is encrypted during transmission and storage.\n• Secure Infrastructure: Regular security audits and updates.\n• Compliance: Adherence to regulations like the IT Act and data protection laws.\n• Access Controls: Restricted access to personal information to authorized personnel only.\n• Fraud Prevention: Multi-factor authentication and continuous monitoring for suspicious activities.'
        },
        {
            question: 'How does Credit Chakra assist with loan repayments?',
            answer: 'Our repayment support includes:\n\n• Automated Reminders: Receive notifications before due dates.\n• Flexible Scheduling: Options to adjust repayment schedules if necessary.\n• Online Payment Portal: Make repayments directly through our secure platform.\n• Financial Tools: Access budgeting and cash flow management tools to plan repayments.\n• Automatic Repayments: Set up auto-debit instructions from your bank account to ensure timely repayments.'
        },
        {
            question: 'How do I access financial advisory services?',
            answer: 'You can:\n\n• Schedule Appointments: Book a one-on-one consultation with our financial experts through your account dashboard.\n• Attend Workshops/Webinars: Join our educational events to enhance your financial knowledge.\n• Access Resources: Utilize guides, articles, and tools available on our platform.'
        },
        {
            question: 'How can my organization partner with Credit Chakra?',
            answer: 'We welcome partnerships with:\n\n• Financial Institutions: Enhance your MSME lending with our tools.\n• Technology Providers: Collaborate on innovative solutions.\n• MSME Associations: Work together to empower small businesses.\n\nContact us at partnerships@creditchakra.com to discuss opportunities.'
        },
        {
            question: 'How does Credit Chakra support financial inclusion?',
            answer: 'We support inclusion by:\n\n• Accessible Services: Simplifying processes for underserved MSMEs.\n• Education Programs: Enhancing financial literacy through workshops and resources.\n• Innovative Solutions: Offering products that address specific needs, such as AI-driven credit scoring and personalized financial advisory.'
        },
        {
            question: 'What makes Credit Chakra different from other fintech platforms?',
            answer: 'Our uniqueness lies in:\n\n• Holistic Approach: Covering the entire loan lifecycle, from application to repayment.\n• Advanced Technology: Leveraging AI and data analytics to improve credit assessments and financial services.\n• Personalized Support: Offering tailored advisory services to meet individual business needs.\n• Commitment to MSMEs: Focusing specifically on the needs of small businesses to empower growth and success.'
        }
    ];

    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const renderAnswer = (answer) => {
        return answer.split('\n\n').map((text, index) => {
            if (text.startsWith('•')) {
                return (
                    <ul key={index} className="list-disc list-inside ml-5">
                        {text.split('•').map((item, i) => item && <li key={i}>{item.trim()}</li>)}
                    </ul>
                );
            }
            return <p key={index} className="mb-4 text-gray-700">{text}</p>;
        });
    };

    return (
        <section className="faq-section py-16 bg-light-blue">
            <div className="container mx-auto px-4">
                <h1 className="text-4xl font-bold mb-12 text-center text-dark-teal">Frequently Asked Questions (FAQs)</h1>
                <div className="space-y-6">
                    {faqs.map((faq, index) => (
                        <div key={index} className="faq-item p-6 rounded-lg shadow-lg border border-light-blue">
                            <div 
                                className="faq-question cursor-pointer text-xl font-semibold flex justify-between items-center"
                                onClick={() => toggleFAQ(index)}
                            >
                                {faq.question}
                                <span className="faq-icon text-yellow-500">{openIndex === index ? '-' : '+'}</span>
                            </div>
                            {openIndex === index && (
                                <div className="faq-answer mt-4 text-gray-700 animate-fade-in">
                                    {renderAnswer(faq.answer)}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FAQ;