import React from 'react';
import { Link } from 'react-router-dom';

const Products = () => {
    const products = [
        {
            id: 1,
            title: 'Chakra Quick Loan',
            description: 'Fast access to capital for short-term business needs. (Working Capital Loans)',
            details: [
                'Loan Amounts: ₹1,00,000 to ₹1,00,00,000',
                'Repayment Terms: 3 to 12 months',
                'Interest Rates: Competitive rates (based on credit score)',
                'Features: Minimal documentation, fast approval',
                'Best For: Small businesses needing immediate funds to cover operational expenses or short-term opportunities.'
            ],
            icon: 'M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
        },
        {
            id: 2,
            title: 'Chakra Growth Fund',
            description: 'Support for long-term investments in growth and expansion, including equipment, infrastructure, or market development. (Term Loans for Expansion)',
            details: [
                'Loan Amounts: ₹10,00,000 to ₹5,00,00,000',
                'Repayment Terms: 1 to 7 years',
                'Interest Rates: Fixed or floating interest rates',
                'Features: Flexible repayment schedules, collateral-free option',
                'Best For: MSMEs looking to invest in machinery, technology, or new markets.'
            ],
            icon: 'M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01'
        },
        {
            id: 3,
            title: 'Chakra Overdraft Facility',
            description: 'A revolving overdraft facility for businesses needing flexible access to funds for short-term needs.',
            details: [
                'Overdraft Limits: ₹5,00,000 to ₹1,00,00,000',
                'Interest: Pay interest only on the overdrawn amount',
                'Features: 24/7 access to funds, minimal collateral for smaller overdrafts, automatic replenishment upon repayment.',
                'Best For: MSMEs managing fluctuating cash flow or needing emergency funds for working capital.'
            ],
            icon: 'M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H6a2 2 0 00-2 2v14a2 2 0 002 2z'
        },
        {
            id: 4,
            title: 'Chakra Invoice Financing',
            description: 'Unlock the value of unpaid invoices for immediate liquidity.',
            details: [
                'Finance Amount: Up to 90% of invoice value',
                'Interest Rates: Based on tenure of the invoice and customer credit profile',
                'Features: No additional collateral required, quick disbursement, flexible repayment aligned with invoice due dates.',
                'Best For: Businesses with long receivable cycles.'
            ],
            icon: 'M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z'
        },
        {
            id: 5,
            title: 'Chakra Supply Chain Financing',
            description: 'Financing tailored for procurement, inventory management, and optimizing the supply chain.',
            details: [
                'Loan Amounts: ₹5,00,000 to ₹2,00,00,000',
                'Repayment Terms: Up to 12 months',
                'Interest Rates: Competitive rates based on business and supplier profiles',
                'Features: Collateral-free for eligible businesses, easy procurement funding, supports long-term supplier relationships',
                'Best For: MSMEs needing liquidity to maintain or enhance supply chains without impacting working capital.'
            ],
            icon: 'M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z'
        },
        {
            id: 6,
            title: 'Chakra Revenue-Based Financing',
            description: 'Flexible loan repayments tied to monthly business revenues, offering a dynamic repayment schedule.',
            details: [
                'Loan Amounts: ₹10,00,000 to ₹2,50,00,000',
                'Repayment: Based on a fixed percentage of monthly revenue until the loan is fully repaid',
                'Interest Rates: Tailored to business performance',
                'Features: No fixed monthly payments, no equity dilution, flexible repayment based on revenue fluctuations',
                'Best For: High-growth businesses in e-commerce, retail, and services with variable monthly revenues.'
            ],
            icon: 'M13 10V3L4 14h7v7l9-11h-7z'
        },
    ];

    return (
        <>
            <section className="py-16 bg-light-blue">
                <div className="container mx-auto px-6">
                    <h1 className="text-4xl font-bold mb-8 text-center text-dark-teal">Our Products</h1>
                    <p className="text-xl mb-12 text-center max-w-3xl mx-auto">
                        Discover Credit Chakra's range of innovative financial products designed specifically for Micro, Small, and Medium Enterprises in India. Our solutions are tailored to meet your unique business needs and drive growth.
                    </p>
                    
                    <div className="grid md:grid-cols-2 gap-8">
                        {products.map((product) => (
                            <div key={product.id} className="bg-white p-8 rounded-lg shadow-md hover-card">
                                <div className="flex items-center mb-6">
                                    <div className="bg-yellow-500 rounded-full p-3 mr-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={product.icon} />
                                        </svg>
                                    </div>
                                    <h2 className="text-2xl font-semibold text-dark-teal">{product.title}</h2>
                                </div>
                                <p className="mb-6 text-gray-700">{product.description}</p>
                                <ul className="list-disc list-inside mb-6 text-gray-700">
                                    {product.details.map((detail, i) => (
                                        <li key={i}>{detail}</li>
                                    ))}
                                </ul>
                                <Link to="/apply" className="btn btn-primary py-2 px-4 rounded bg-yellow-500 text-dark-teal font-semibold hover:bg-yellow-600 transition duration-300 inline-block">Apply Now</Link>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="py-16 bg-dark-teal text-white">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-bold mb-8 text-center">Tailored Solutions for Your Industry</h2>
                    <p className="text-xl text-white mb-8 text-center max-w-3xl mx-auto">
                        We understand that different industries have unique financial needs. That's why we offer customized product packages designed specifically for various sectors.
                    </p>
                    <div className="grid md:grid-cols-3 gap-8">
                        <div className="bg-white p-6 rounded-lg shadow-md text-center hover-card">
                            <h3 className="text-xl font-semibold mb-4 text-dark-teal">Manufacturing</h3>
                            <p className="text-gray-700">Specialized financing for equipment purchase, inventory management, and production scaling.</p>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-md text-center hover-card">
                            <h3 className="text-xl font-semibold mb-4 text-dark-teal">IT & Software</h3>
                            <p className="text-gray-700">Flexible funding options for project-based work, talent acquisition, and infrastructure setup.</p>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-md text-center hover-card">
                            <h3 className="text-xl font-semibold mb-4 text-dark-teal">E-commerce</h3>
                            <p className="text-gray-700">Quick working capital solutions for inventory purchases, marketing campaigns, and logistics support.</p>
                        </div>
                    </div>
                    <div className="mt-12 text-center">
                    <Link to="/contact" className="btn btn-primary py-3 px-6 rounded-lg bg-yellow-500 text-dark-teal font-semibold hover:bg-yellow-600 transition duration-300 inline-block">Discuss Your Industry Needs</Link>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-light-blue">
                <div className="container mx-auto px-6 text-center">
                    <h2 className="text-3xl font-bold mb-8 text-dark-teal">Ready to Fuel Your Business Growth?</h2>
                    <p className="text-xl mb-8 max-w-2xl mx-auto">
                        Explore our range of financial products designed to support your MSME at every stage of growth. Let's work together to find the perfect solution for your business needs.
                    </p>
                    <Link to="/apply" className="btn btn-primary py-3 px-6 rounded-lg bg-yellow-500 text-dark-teal font-semibold hover:bg-yellow-600 transition duration-300 inline-block">Get Started Today</Link>
                </div>
            </section>
        </>
    );
};

export default Products;